(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/middleware-payment-done/assets/messages/error-message.js') >= 0) return;  svs.modules.push('/components/payment/middleware-payment-done/assets/messages/error-message.js');
"use strict";


const {
  utils
} = svs.components.payment.middleware.paymentDone;
const {
  PayableType
} = svs.components.payment.common.constants;
const {
  getTitleAndErrorMessageFromClientError
} = svs.components.payment.common.error.utils;
const getFractionErrorMessage = (unpaidWagers, paymentError) => {
  var _errors$;
  const errors = paymentError.toJSON();
  return getTitleAndErrorMessageFromClientError(errors === null || errors === void 0 || (_errors$ = errors[0]) === null || _errors$ === void 0 ? void 0 : _errors$.clientCode);
};
const getWagerErrorMessage = (unpaidWagers, paymentError, paymentInfo) => {
  var _errors$2;
  const totalUnpaid = unpaidWagers.length;
  const unpaidProducts = utils.getProductsString(unpaidWagers, paymentInfo);
  const errors = paymentError.toJSON();
  const clientErrorObject = getTitleAndErrorMessageFromClientError(errors === null || errors === void 0 || (_errors$2 = errors[0]) === null || _errors$2 === void 0 ? void 0 : _errors$2.clientCode);
  const message = [clientErrorObject.message];
  const errorMessage = utils.numerous(totalUnpaid)(['Ditt', 'Dina'])("{#0} spel p\xE5 ".concat(unpaidProducts, " gick inte igenom"));
  message.push(errorMessage);
  clientErrorObject.message = message.join(' ');
  return clientErrorObject;
};
const getErrorMessage = (unpaidPayables, paymentError, paymentInfo) => {
  var _unpaidPayables$;
  const type = unpaidPayables === null || unpaidPayables === void 0 || (_unpaidPayables$ = unpaidPayables[0]) === null || _unpaidPayables$ === void 0 ? void 0 : _unpaidPayables$.type;
  switch (type) {
    case PayableType.FRACTION:
      return getFractionErrorMessage(unpaidPayables, paymentError);
    case PayableType.WAGER:
      return getWagerErrorMessage(unpaidPayables, paymentError, paymentInfo);
    default:
      return getTitleAndErrorMessageFromClientError();
  }
};
setGlobal('svs.components.payment.middleware.paymentDone.messages.getErrorMessage', getErrorMessage);

 })(window);