(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/middleware-payment-done/assets/messages/successful-message.js') >= 0) return;  svs.modules.push('/components/payment/middleware-payment-done/assets/messages/successful-message.js');
"use strict";

const {
  Currency: currency
} = svs.utils.format;
const {
  groupTypes
} = svs.components.marketplaceData.constants;
const {
  getAccountBalance
} = svs.components.marketplaceShared.getBalance;
const {
  utils
} = svs.components.payment.middleware.paymentDone;
const {
  paymentInfoType
} = svs.components.payment.common.constants;
const {
  productIds
} = svs.utils.products;
const {
  getGrasrotenMessageForDialogOrToaster
} = svs.sport.sport_common;
const {
  logger
} = svs.components.payment.common;
const getPaymentMessage = async (paidWagers, unpaidWagers, wagersDTO, paymentInfo) => {
  const headline = [];
  const footnote = [];
  const totalPaidAmount = wagersDTO.reduce((sum, item) => sum + item.cost, 0);
  const totalPayRequestAmount = paidWagers.reduce((sum, item) => sum + item.price, 0);
  const marketplaceWager = paidWagers.find(wager => wager.activatedDrawId);
  let marketplaceUrl;
  if (marketplaceWager && marketplaceWager.role === groupTypes.PRIVATE) {
    const activatedDraw = getAccountBalance.getCachedBalance(marketplaceWager.activatedDrawId);
    marketplaceUrl = utils.getMarketplaceUrl(marketplaceWager.role, activatedDraw === null || activatedDraw === void 0 ? void 0 : activatedDraw.data);
  }
  const isOwnRows = [...paidWagers, ...unpaidWagers].every(wager => wager.hasOwnRows);
  const totalPaid = paidWagers.length;
  const totalUnpaid = unpaidWagers.length;
  let numberOfPaidProducts = totalPaid;
  if (isOwnRows && totalPaid) {
    numberOfPaidProducts = 1;
  }
  const competitionWager = paidWagers.find(wager => wager.isCompetition());
  let compDesc;
  if (competitionWager) {
    var _paymentInfoData$filt;
    const paymentInfoData = paymentInfo.get();
    const postPaymentInfo = (_paymentInfoData$filt = paymentInfoData.filter(info => info.type === paymentInfoType.POST && info.localId === competitionWager.localId)) === null || _paymentInfoData$filt === void 0 ? void 0 : _paymentInfoData$filt.pop();
    compDesc = postPaymentInfo.extraData.compName;
    const isCompetitionSignUp = postPaymentInfo.extraData.isCompetitionSignUp;
    isCompetitionSignUp && footnote.unshift("Du \xE4r anm\xE4ld till ".concat(compDesc, ". <br /> <br />"));
  }

  const diffAmount = totalPayRequestAmount - totalPaidAmount;
  const challengeWager = paidWagers.find(wager => wager.getProductBet.productId === productIds.CHALLENGE);
  const impossibleRows = challengeWager ? diffAmount / challengeWager.getProductBet.rowPriceMulti / challengeWager.getProductBet.rowPrice : 0;
  if (impossibleRows) {
    logger.info("Impossible rows found, showing customer dialog. Impossible rows: ".concat(impossibleRows, ", paid: ").concat(totalPaidAmount, ", requested: ").concat(totalPayRequestAmount));
    headline.push('Dina kombinationer går inte att vinna på');
    footnote.push("Ditt system inneh\xF6ll ".concat(impossibleRows, " rad").concat(impossibleRows > 1 ? 'er' : '', "\n    med kombinationer som inte \xE4r m\xF6jliga att vinna p\xE5. Den nya systemkostnaden blev\n    ").concat(currency(totalPaidAmount), " kr.<br />"));
    if (marketplaceWager && marketplaceWager.role === groupTypes.RETAILER) {
      footnote.push("<br /> Du kan nu st\xE4nga fliken.");
    } else {
      footnote.push(utils.numerous(numberOfPaidProducts)(['det', 'dem'])("Du hittar {#0} under Mina spel".concat(marketplaceUrl ? " och p\xE5 <a class=\"alert-text-link\" href=\"".concat(marketplaceUrl, "\">lagsidan</a>.") : '.')));
    }
    return [headline.join(' '), footnote.join(' ')];
  }
  if (totalPaid) {
    const paidProducts = utils.getProductsString(paidWagers, paymentInfo);
    headline.push(utils.numerous(numberOfPaidProducts)(['Ditt', 'Dina'], ['betalat', 'betalade'])("{#0} spel p\xE5 ".concat(paidProducts, " \xE4r {#1}")));
    if (marketplaceWager && marketplaceWager.role === groupTypes.RETAILER) {
      footnote.push(utils.numerous(numberOfPaidProducts)(['Spelet', 'Spelen'], ['det', 'dem'])("{#0} kostade ".concat(currency(totalPaidAmount), " kr. <br /> Du kan nu st\xE4nga fliken.")));
    } else {
      footnote.push(utils.numerous(numberOfPaidProducts)(['Spelet', 'Spelen'], ['det', 'dem']
      )("{#0} kostade ".concat(currency(totalPaidAmount), " kr. Du hittar").concat(compDesc ? " det i ".concat(compDesc, "-fliken och") : " {#1}", " under Mina spel").concat(marketplaceUrl ? " och p\xE5 <a class=\"alert-text-link\" href=\"".concat(marketplaceUrl, "\">lagsidan</a>.") : '.')));
    }
  }
  if (totalUnpaid) {
    if (isOwnRows && unpaidWagers.length && paidWagers.length) {
      const totalUnpaidAmount = unpaidWagers.reduce((sum, wager) => sum + wager.price, 0);
      const totalPrice = totalPaidAmount + totalUnpaidAmount;
      const message = ["Ett fel uppstod, endast ".concat(currency(totalPaidAmount), " kr av ").concat(currency(totalPrice), " kr gick att betala."), 'Du kan annullera betalade delar av spelet under Mina spel.'];
      footnote.push("<br />".concat(message.join('<br />')));
    } else {
      const unpaidProducts = utils.getProductsString(unpaidWagers, paymentInfo);
      const errorMessage = utils.numerous(totalUnpaid)(['ditt', 'dina'])("{#0} spel p\xE5 ".concat(unpaidProducts, " gick inte igenom"));
      if (totalPaid) {
        headline.push("men ".concat(errorMessage));
      } else {
        headline.push(errorMessage.at(0).toUpperCase().concat(errorMessage.slice(1)));
      }
    }
  }
  const grasroten = await getGrasrotenMessageForDialogOrToaster();
  if (grasroten.hasSponsorship) {
    footnote.push("<br /> ".concat(grasroten.thankYouMessage));
  }
  const finalHeadline = headline.join(' ');
  const finalFootnote = footnote.join(' ');
  return [finalHeadline || undefined, finalFootnote || undefined];
};
setGlobal('svs.components.payment.middleware.paymentDone.messages.getPaymentMessage', getPaymentMessage);

 })(window);