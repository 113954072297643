(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/middleware-payment-done/assets/payment-done.js') >= 0) return;  svs.modules.push('/components/payment/middleware-payment-done/assets/payment-done.js');
"use strict";


const {
  getErrorMessage,
  getPaymentMessage
} = svs.components.payment.middleware.paymentDone.messages;
const {
  PayableType
} = svs.components.payment.common.constants;
const {
  analytics
} = svs.components;
const {
  groupTypes
} = svs.components.marketplaceData.constants;
const {
  getDisplayName
} = svs.utils.products;
const trackEvent = _ref => {
  let {
    trackingAction,
    paidProductIds
  } = _ref;
  const trackingProductName = Array.isArray(paidProductIds) ? getDisplayName(paidProductIds[0]) : getDisplayName(paidProductIds);
  analytics.trackEvent({
    category: 'overlays',
    action: trackingAction,
    opt_label: trackingProductName
  });
};
const paymentDone = (onInteractionSuccess, onInteractionFailure) => _ref2 => {
  let {
    payment,
    paymentInfo,
    userContext
  } = _ref2;
  return async next => {
    try {
      const {
        result,
        error
      } = payment.paymentResponse;
      const paidItems = result.getPaidPayableItems();
      const payableType = payment.getPayableType();
      let interactionProps;
      let dialogTitle;
      let dialogMessage = '';
      if (paidItems.length > 0) {
        switch (payableType) {
          case PayableType.FRACTION:
            interactionProps = {
              title: 'Du har köpt en andel',
              message: dialogMessage,
              onCallback: action => {
                if (userContext.callback) {
                  userContext.callback();
                }
                analytics.trackEvent({
                  category: 'overlays',
                  action,
                  opt_label: 'Fraction'
                });
              }
            };
            break;
          case PayableType.WAGER:
            {
              const paidProductIds = [...new Set(paidItems.reduce((products, wager) => products.concat(wager.getProductBet.productId), []))];
              const mybetsUrl = svs.components.payment.middleware.paymentDone.utils.getMybetsUrl(paidProductIds);
              const marketplaceWager = paidItems.find(wager => wager.getIsMarketplaceWager());
              [dialogTitle, dialogMessage] = await getPaymentMessage(paidItems, result.getUnpaidPayableItems(), result.getPaidPayableItemsDTO(), paymentInfo);
              interactionProps = {
                title: dialogTitle,
                message: dialogMessage,
                mybetsUrl,
                isRetailer: marketplaceWager && marketplaceWager.role === groupTypes.RETAILER,
                onCallback: action => {
                  trackEvent({
                    trackingAction: action,
                    paidProductIds
                  });
                }
              };
              break;
            }
          default:
        }
        await onInteractionSuccess(interactionProps);
      } else if (error) {
        var _errorObjects$0$clien, _errorObjects$;
        const clientErrors = getErrorMessage(result.getUnpaidPayableItems(), error, paymentInfo);
        const errorObjects = error.toJSON();
        const clientCode = (_errorObjects$0$clien = (_errorObjects$ = errorObjects[0]) === null || _errorObjects$ === void 0 ? void 0 : _errorObjects$.clientCode) !== null && _errorObjects$0$clien !== void 0 ? _errorObjects$0$clien : undefined;
        onInteractionFailure({
          clientErrors,
          clientCode
        });
      }
    } catch (error) {
      onInteractionFailure({
        clientErrors: getErrorMessage()
      });
    }
    next();
  };
};
setGlobal('svs.components.payment.middleware.paymentDone.paymentDone', paymentDone);

 })(window);