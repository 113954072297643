(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/middleware-payment-done/assets/utils/payable-utils.js') >= 0) return;  svs.modules.push('/components/payment/middleware-payment-done/assets/utils/payable-utils.js');

'use strict';

const {
  groupTypes
} = svs.components.marketplaceData.constants;
const {
  urlMapping
} = svs.core;
const {
  getTeamUrl
} = svs.components.marketplace.helpers;
const {
  isTipsetProduct,
  getProductClassName
} = svs.utils.products;
const {
  paymentInfoType
} = svs.components.payment.common.constants;
const numerous = total => function () {
  for (var _len = arguments.length, dictionary = new Array(_len), _key = 0; _key < _len; _key++) {
    dictionary[_key] = arguments[_key];
  }
  return stringLiteral => {
    const wordToSelect = total > 1 ? 1 : 0;
    const wordList = dictionary.map(words => words[wordToSelect]);
    return stringLiteral.replace(/{#(\d)}/g, (match, index) => wordList[index]);
  };
};
const getProductsString = (wagers, paymentInfo) => {
  const competitionWager = wagers.find(wager => wager.isCompetition());
  let compDesc;
  if (competitionWager) {
    var _paymentInfoData$filt;
    const paymentInfoData = paymentInfo.get();
    const postPaymentInfo = (_paymentInfoData$filt = paymentInfoData.filter(info => info.type === paymentInfoType.POST && info.localId === competitionWager.localId)) === null || _paymentInfoData$filt === void 0 ? void 0 : _paymentInfoData$filt.pop();
    compDesc = postPaymentInfo.extraData.compName;
  }
  const paidProducts = [...new Set(wagers.reduce((products, wager) => products.concat(wager === competitionWager && compDesc ? compDesc : wager.getProductsInBet()), []))];
  if (paidProducts.length === 1) {
    return paidProducts.join('');
  }
  return "".concat(paidProducts.slice(0, -1).join(', '), " och ").concat(paidProducts.slice(-1).pop());
};
const getMarketplaceUrl = (role, activatedDrawData) => {
  if (role === groupTypes.PRIVATE && activatedDrawData) {
    return "".concat(urlMapping.get('marketplace')).concat(getTeamUrl(activatedDrawData.GroupId, activatedDrawData.GroupName, 'spel', activatedDrawData.ContainerId, activatedDrawData.activatedDrawId));
  }
  return '';
};
const getMybetsUrl = productIds => {
  const isTipsenProduct = isTipsetProduct(productIds[0]);
  let returnUrl = 'myBetsHome';
  if (isTipsenProduct && productIds.length <= 2) {
    returnUrl = "".concat(getProductClassName(productIds[0]), "MyBetsHome");
  }
  return urlMapping.get(returnUrl);
};
const TRACKING_ACTIONS = {
  MY_GAMES: 'my-games',
  OK: 'ok'
};
const toExport = {
  numerous,
  getProductsString,
  getMarketplaceUrl,
  getMybetsUrl,
  TRACKING_ACTIONS
};
setGlobal('svs.components.payment.middleware.paymentDone.utils', toExport);

 })(window);